<template>
  <div class="block-wrapper">
    <a-form @submit.prevent="handleSubmit">
      <a-form-item label="Статус">
        <a-checkbox v-model="filter.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Ключ">
        <a-input v-model="filter.name" required />
      </a-form-item>

      <a-form-item label="Значение">
        <a-input v-model="filter.slug" required />
      </a-form-item>

      <a-form-item label="Тип">
        <a-input v-model="filter.type" required />
      </a-form-item>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Создать
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { SAVE_FILTER } from '@/store/types'

export default {
  data() {
    return {
      filter: {
        name: '',
        slug: '',
        type: '',
        is_active: false,
      },
    }
  },

  methods: {
    handleSubmit() {
      this.$store.dispatch(SAVE_FILTER, this.filter)
    },
  },
}
</script>
